h1,
h2,
h3 {
  font-family: "Product Sans", "Roboto";
  font-weight: 500;
  font-size: 26px;
  letter-spacing: 0.5px;
  line-height: 1.1;
  color: inherit;
  text-align: left;
}

body {
  display: block;
  margin: 0px !important;
}

strong {
  font-weight: bold;
  color: #f93b57;
}

.form-control:focus {
  box-shadow: none;
}

.hidden {
  display:none;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.App {
  display: block;
  font-family: "Product Sans", "Roboto";
  text-align: center;
  width: 100%;
  min-height: 100vh;
  left: 0 !important;
  top: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  min-height: 100vh;
  flex-grow: 1;
}

.Toolbar {
  align-items: flex-start;
  background-color: #fff;
  /*'@media all': {
    min-height: 128;
  }*/
}

.Header {
  font-family: "Product Sans", "Roboto";
  font-size: 56px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 1px;
  color: #222;
  margin-top: 100px;
  margin-bottom: 30px;
}

.Header..css-1e3tmb2-MuiTypography-root {
  font-family: "Product Sans", "Roboto";
}

.Body {
  display: inline-block;
  margin: auto;
}

.Hello {
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.5px;
  color: #f93b57;
  margin-top: 20px;
  margin-left: 20px;
}

.Welcome {
  font-size: 24px;
  font-weight: 400;
  color: #333;
  text-align: left;
  letter-spacing: 0.1px;
  margin-left: 20px;
  margin-bottom: 40px;
}


.NewHeader {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 30px;
}
	
.NewSubHeader {
  font-size: 17px;
  font-weight: 400;
  color: #333;
  letter-spacing: 0.5px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
}

.Drawer .MuiDrawer-paper {
    background-color: #fff8f8;
    border-width: 0;
    margin: 0 !important;
    padding: 0 !important;
}

.Button {
  border-radius: 16em !important;
  padding: 10px !important;
  background-color: #f93b57;
  border: 0;
  text-transform: capitalize !important;
  color: white;
  font-size: 16px !important;
  margin-top: 8px !important;
  min-width: 20% !important;
  width: 27ch !important;
}

.Search {
  display: flex;
  padding-right: 4px;
}

.Select {
  margin-left: 10.5px;
  margin-right: 0px;
  border: 0px;
  width: auto;
}

.Select.MuiInputBase-input {
    display: inline-flex;
    border: 0px;
    color: #333;
    font-size: 13.5px;
    font-family: "Product Sans", "Roboto";
    margin: auto;
}

.css-g5mnl7-MuiInputBase-root .MuiInputBase-input {
  font-size: 14px !important;
  color: #222;
  font-weight: 400 !important;
}

.Level {
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
    margin-left: auto;
}

  .LevelItem {
    font-size: 15px !important;
    margin-left: 1px;
    margin-right: 1px;
    padding: 0 !important;
    margin: auto;
}

.Levelcon {
  max-height: 16px;
  max-width: 16px;
}

.Card {
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgb(60 64 67 / 30%), 0px 4px 8px 3px rgb(60 64 67 / 15%);
  margin: 0 16px;
  padding: 0 16px;
  position: relative;
}

.Panel {
  display: block;
  padding-top: 36px;
  padding-bottom: 20px;
}

.PanelSmall {
  display: none;
}

.PanelLarge {
  display: block;
  padding-top: 42px;
  padding-bottom: 20px;
  text-align: left;
  align-items: center;
}

.Form {
  margin-left: 15%;
}

.Table {
  display: inline-block !important;
  margin-bottom: 16px;
  max-width: 878px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    background-color: #fff;
    -webkit-transition: none !important;
    transition: none !important;
    border: solid #e2e2e2;
    border-width: 1px;
    border-radius: 10px !important;
    box-shadow: none !important;
    overflow: hidden;
}

.css-177gid-MuiTableCell-root {
    border-bottom: 0 !important;
    max-width: 24px !important;
}

.Column {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: center;
}

.Row {
  font-family: "Product Sans";
  font-size:16px;
  text-align: left;
  padding-left: 20px;
}

.Row .First {
  font-weight: 500;
  min-width: 150px;
}

.Row .Second {
  min-width: 100px;
}

.Row .Third {
  min-width: 50px;
}

.Row .Last {
  color: #555;
  text-align: right;
}

.Normal {
  font-weight: 400;
}

.Bold {
  font-weight: 500;
}

.Light {
  font-weight: 300;
}

.Small {
  font-family: "Roboto";
  font-size:12.5px;
  color: #888;
  text-align: left;
}

.Section {
  display: flex;
}

.SectionMargin {
  display: flex;
  margin-top: 68px;
  margin-left: 10%;
  margin-right: 0;
  margin-bottom: 68px;
}

.Single {
  min-width: 320px;
}

.Range {
  min-width: 320px !important;
}

.Date {
  margin-left: 3px;
  margin-right: 1px;
}

.Grid {
  margin-left: 20%;
  margin-right: 20%;
}

.Box {
  text-align: left;
  white-space: nowrap;
  /*--'& > img': {
    mr: 2;
    flex-shrink: 0;
  }--*/
}

.Paper {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 36px;
  position: relative;
}

.Banner {
  margin-top: 80px;
  margin-left: -15px;
  width: 30%;
}

.Promo {
  margin-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 100px;
  max-width: 400;
}


.Swipe {
  flex-grow: 1;
}

.SwipeImg {
  height: 320px;
  display: inline-block;
  min-width: 40%;
  background-position: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  overflow: hidden;
}

.SwipeText {
  display: block;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  position: relative;
  top: -80%;
  left: 50%;
  transform: translate(-50%, -300px);
}

.Icon {
  padding-right: 10px !important;
}

.IconSmall {
  padding-right: 10px !important;
}

.Adorn {
  max-height: 18px;
  max-width: 18px;
  padding-left: 4px;
  padding-right: 8px;
}

.AdornSmall {
  max-height: 18px;
  max-width: 18px;
  color: #555;
}

.AdornLarge {
  height: 30px !important;
  width: 30px !important;
  color: #aaa;
  margin-top: 4px;
  margin-bottom: 4px;
}

.AdornMedium {
  color: #777;
}

.Center {
    display: inline-flex;
}

.VCenter {
  display: flex;
  align-items: center;
}

.Dot {
  margin-top: -2px;
  padding-left: 10px;
  padding-right: 10px;
}

.Text {
  font-family: "Product Sans";
  font-size:15px;
  text-transform:capitalize;
  color: #555;
}

.Title {
  display: block;
  font-size: 24px;
  color: #111;
  text-align: left !important;
  margin-left: 8px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.SubTitle {
  font-size: 20px;
  text-align: left !important;
  margin-left: 8px;
  color: #333;
}

.Alert {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.MuiAlert-message {
  font-family: "Product Sans";
  font-size:13.5px;
}

.Large {
  font-size:32px;
  color: #111;
}

.Travel {
  font-family: "Product Sans";
  font-size:16px;
  text-align: left;
  padding: 3px;
}

.TravelLight{
  font-family: "Product Sans";
  font-size:15px;
  color: #888 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Menu {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.MenuMargin {
  margin-left: 6px;
}

.MenuItem {
  color: #666 !important;
  font-family: "Roboto" !important;
  padding: 14px !important;
  min-width: 150px;
}

.MenuItem.Mui-active {
  color: #222;
  background-color: #fff;
}

.MenuItem.Mui-focused {
  color: #222;
  background-color: #fff;
}

.MenuItem.Mui-selected {
  color: #222;
  background-color: #fff;
}

.MenuItem:hover {
  color: #222;
  background-color: #fff;
}

.MenuItem.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.Mui-focused{
    background-color: #fff;
}

.MenuItem.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.Mui-selected{
    background-color: #fff;
    color: #f93b57;
}

.MenuItem.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover{
  background-color: #f9eef0;
}

.PopOver {
  color: #444;
  font-family: "Product Sans", "Roboto" !important;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-end;
}

.PopBox {
  display: inline-flex;
  height: auto;
  max-height: 2.0775em;
  align-items: center;
  padding-left: 8px;
  line-height: 1.4375em !important;
}

.PopText {
  color: #22;
  font-family: "Product Sans", "Roboto" !important;
  font-weight: 400;
  font-size: 13.5px;
}

.PopIcon {
  padding-left: 8px;
  color: #777;
}

.PopButtons {
  display: flex;
  justify-content: flex-end;
}

.PopButton {
  font-family: "Product Sans", "Roboto" !important;
  text-transform:capitalize !important;
}

.Autocomplete {
  display: flex;
  margin: 10px;
  min-width: 240px;
  border-color: #eee;
}

.Autocomplete.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f93b57 !important;
}

.TextField {
  font-family: "Product Sans", "Roboto";
  font-size: 40px;
}

.TextField .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f93b57 !important
}

.ToggleGroup {
  display: flex !important;
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 40px;
}

.ToggleButton {
  border-radius: 0px !important;
}

.ToggleButton.Mui-focused {
  color: #f93b57 !important;
}

.ToggleButton.Mui-selected {
  color: #f93b57 !important;
}

.ToggleButton:hover {
  color: #f93b57 !important;
}

.FormControl {
  margin-top: 6px !important;
  margin-right: 20px !important;
  min-width: 90px !important;
  color: #444 !important;
  border-color: #c4b8bb !important;
}

.Progress {
  margin-top: 16px;
  margin-bottom: 16px;
  display: block;
  color: #f93b57;
}

/*--responsive--*/
@media screen and (max-width: 1280px) {
  .Drawer.css-11o2879-MuiDrawer-docked .MuiDrawer-paper { width: 0px; }
  .Banner {  margin-top: 5em; width: 60%; max-width: 480px; }
  .Body { display: block; }
  .Header { margin-top: 60px; font-size: 0px; margin-bottom: 16px; }
  .Hello {
    font-size: 24px;
    margin-left: 24px;
  }
  .Welcome {
    font-size: 18px;
    margin-left: 24px;
    margin-bottom: 20px;
  }
  .NewHeader { font-size: 20px; }
  .NewSubHeader { font-size: 15px; }
  .Card {
    display: flex;
    justify-content: space-between;
    background: none;
    background-color: var(--travel-internal-color-surface);
    border-radius: 0 0 16px 16px;
    box-shadow: 0 2px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px -6px rgb(60 64 67 / 15%);
    padding: 0 16px 12px 16px;
    margin: 0;
    width: calc(100% - 32px);
  }
  .Paper { padding-top: 6px; padding-bottom: 0px; margin: 0; width: 100% }
  .Panel { padding-top: 20px; }
  .PanelSmall { display: flex; }
  .PanelLarge { display: none; }
  .Range{ min-width: 100px !important; }
  .Table { margin-top: 8px; margin-bottom: 8px; max-width: 94%; }
  .Row .First { min-width: 100px; }
  .Row .Second { min-width: 70px; }
  .Row .Third { display: none; }
  .Form {margin: auto;}
  .FormControl { margin-right: 12px; }
  .Button { display: block; margin-left :0%; margin-right: 0%}
  .Icon { padding-right: 5px;}
  .Section.Margin { display: flex; margin-left: 9px; }
  .Search { display: block; padding-bottom: 12px; }
  .SectionMargin { display: inline-block; margin-left: 20px !important; margin-right: 20px !important;}
  .Select {
    margin: auto;
    width: auto;
  }
  .css-g5mnl7-MuiInputBase-root .MuiInputBase-input {
    font-size: 15px !important;
  }
  .PopText {
    font-size: 15px !important;
  }
  .Autocomplete {
    margin-left: 8px;
    margin-right: 5px;
    width: 50%;
    min-width: 0;
  }
  .Adorn { padding-left: 0; }
  .AdornLarge {
    height: 20px !important;
    width: 20px !important;
    color: #aaa;
    margin: 8px;;
  }
  .Center { display: inline-block; }
  .Title {
    font-size: 18px;
    margin-top: 10px;
  }
  .SubTitle {
    font-size: 16px;
  }
  .Large {
    font-size:16px;
    align-items: flex;
    color: #333;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ToggleGroup {
    margin-left: 0;
  }
  .Promo {
    margin: 0px;
  }
  .SwipeImg {
    height: 180px;
    width: 99%;
    max-width: 400px;
  }
  .SwipeText {
    font-size: 24px;
    transform: translate(-50%, -160px);
  }
  .Icon {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .Field {
    width: 360px !important;
  }
}

@media screen and (max-width: 320px) {
  .Field {
    width: 240px !important;
  }
}

@media screen and (max-width: 240px) {
  .Field {
    width: 120px !important;
  }
}
