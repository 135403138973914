
.Field {
  width: 25ch !important;
}


/*--responsive--*/
@media screen and (max-width: 1280px) {
  .Field {
    width: 50vh !important;
  }
}
